<template>
  <v-dialog persistent v-model="showPaymentMethodModal" width="400">
    <v-card>
      <v-card-title class="headline grey lighten-2" primary-title> Select payment method </v-card-title>

      <v-card-text>
        <v-select class="mt-6 mb-4" :items="methods" label="Select payment method" v-model="paymentMethod"></v-select>

        <v-text-field v-model="extraData" label="Extra data"></v-text-field>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeModal()"> Cancel </v-btn>
        <v-btn color="success" @click="submit()" large class="ps-6 pe-8" :disabled="!paymentMethod">
          <v-icon right>mdi-content-save</v-icon> Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['showPaymentMethodModal', 'paymentMethodParent'],
  data() {
    return {
      disableSaveBtn: true,
      paymentMethod: null,
      extraData: '',
      methods: ['Accounts receivable', 'ATM', 'AZV', 'Cash', 'Insurance', 'Voucher']
    };
  },
  mounted() {
    //if set data from parent set in child
    if (this.paymentMethodParent) {
      this.paymentMethod = this.paymentMethodParent;
    }
  },
  methods: {
    closeModal() {
      this.$parent.showPaymentMethodModal = false;
    },
    submit() {
      this.closeModal();
      let paymentMethod = this.paymentMethod;
      const extraData = this.extraData.trim();
      if (extraData.length > 0) paymentMethod += ':' + extraData;
      this.$emit('submitPaymentMethodParent', paymentMethod);
    }
  }
};
</script>
