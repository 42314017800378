<template>
  <v-dialog ref="dialogBirthDate" v-model="dialogBirthDate" :close-on-content-click="false" transition="scale-transition" width="290px">
    <template v-slot:activator="{ on }">
      <v-text-field
        class="birthdaypicker-custom"
        :class="required ? 'required' : ''"
        :rules="datePickerRules"
        :value="computedBirthDate"
        required
        clearable
        label="Birthday"
        readonly
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker ref="picker" v-model="birthDate" :max="new Date().toISOString().substr(0, 10)" min="1900-01-01" @change="save"> </v-date-picker>
  </v-dialog>
</template>

<script>
import moment from 'moment';

export default {
  props: ['keyName', 'valueFromParent', 'newCase', 'required'],
  data() {
    return {
      dialogBirthDate: false,
      birthDate: null,
      datePickerRules: [],
    };
  },
  watch: {
    dialogBirthDate(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));
    },
    valueFromParent(n) {
      if (n) this.birthDate = moment(n, 'YYYY-MM-DD').format('YYYY-MM-DD');
    },
  },
  computed: {
    computedBirthDate() {
      return this.birthDate ? moment(this.birthDate).format('D-MMMM-YYYY') : '';
    },
  },
  created() {
    if (this.valueFromParent) this.birthDate = moment(this.valueFromParent, 'YYYY-MM-DD').format('YYYY-MM-DD');
    if (this.required) this.datePickerRules = [(v) => !!v || 'This field is required'];
  },
  methods: {
    save(date) {
      this.$refs.dialogBirthDate.save(date);
      this.$emit('updateParentValue', date + 'T13:00:00.000Z[UTC]', this.keyName);
    },

    maxDate() {
      const date = new Date();
      date.setFullYear(date.getFullYear() - 18);
      return date.toISOString().substr(0, 10);
    },
  },
};
</script>