<template>
  <v-menu
    ref="menuDate"
    v-model="menuDate"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :value="computedDate"
        :label="label"
        readonly
        :class="required ? 'required' : ''"
        :disabled="disabled"
        clearable
        @click:clear="clearDate()"
        required
        :rules="datePickerRules"
        v-on="on"
        dense
      ></v-text-field>
    </template>
    <v-date-picker v-model="date" no-title scrollable @change="save"> </v-date-picker>
  </v-menu>
</template>

<script>
import moment from 'moment';

export default {
  props: ['keyName', 'label', 'required', 'valueFromParent', 'disabled', 'dense'],
  data() {
    return {
      date: null,
      menuDate: false,
      datePickerRules: [],
    };
  },
  watch: {
    valueFromParent(n) {
      if (n) this.date = moment(n, 'YYYY-MM-DD').format('YYYY-MM-DD');
    },
  },
  computed: {
    computedDate() {
      return this.date ? moment(this.date).format('D-MMMM-YYYY') : '';
    },
  },
  created() {
    if (this.valueFromParent) {
      this.date = moment(this.valueFromParent, 'YYYY-MM-DD').format('YYYY-MM-DD');
    }
    if (this.required) this.datePickerRules = [(v) => !!v || 'This field is required'];
  },
  methods: {
    save(date) {
      this.$refs.menuDate.save(date);
      this.$emit('updateParentValue', date + 'T01:00:00.000Z[UTC]', this.keyName);
    },

    maxDate() {
      const date = new Date();
      date.setFullYear(date.getFullYear() - 18);
      return date.toISOString().substr(0, 10);
    },

    clearDate() {
      this.$emit('updateParentValue', null, this.keyName);
    },
  },
};
</script>