<template>
  <div :id="id" class="hide-print">
    <div class="text print-subject-registration">
      <img class="logo" :src="require('@/assets/images/generic/logo-dvg.svg')" style="width: 250px" />
      <p>Dear Sir/Madam,</p>
      <p>
        With reference to the ongoing COVID-19 pandemic outbreak, you have agreed as part of the embarkation and disembarkation (ED) card process to
        comply with the rules and regulations set forth by the Aruban government.
      </p>
      <p>
        As a result of this, you will have to comply with the polymerase chain reaction (PCR) test requirement consisting of a nasopharyngeal swab.
        With this form, which is unique to you (see QR code bottom right corner), you will be able to register for the test.
      </p>
      <p>
        As you may already know, by opting to get the PCR test performed here on Aruba, you will have to await your results in quarantine at your host
        hotel. While you wait on your test results you are kindly requested to remain inside of your room. You will receive your test results through
        email. If you do not receive it within 24 hours, please contact us at (+297) 2800101.
      </p>

      <div>If you are experiencing any of these following symptoms, call (+297) 2800101:</div>
      <ul>
        <li>Cough</li>
        <li>Shortness of breath</li>
        <li>Sore throat</li>
        <li>Anosmia</li>
        <li>Myalgia/chills</li>
      </ul>

      <table class="print-subject-registration-table table-sm" v-if="formData">
        <tbody>
          <tr>
            <td>First Name:</td>
            <td>{{ formData.firstName }}</td>
          </tr>
          <tr>
            <td>Last Name:</td>
            <td>{{ formData.lastName }}</td>
          </tr>
          <tr>
            <td>Birth Date:</td>
            <td>{{ computedBirthDate }}</td>
          </tr>
          <tr>
            <td>E-Mail:</td>
            <td>{{ formData.email }}</td>
          </tr>
          <tr>
            <td>Contact Phone number:</td>
            <td>{{ formData.phoneNumber }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <vue-qrcode :value="'^^' + patientCase + '^'" :scale="7" />
  </div>
</template>

<script>
import VueQrcode from 'vue-qrcode';
import moment from 'moment';

export default {
  props: ['formDataParent', 'patientCase', 'id'],
  components: {
    VueQrcode
  },
  data() {
    return {
      formData: null
    };
  },
  watch: {
    formDataParent(n) {
      console.log(n);
      this.formData = n;
    }
  },
  computed: {
    computedArrivalDate() {
      return this.formData.flight?.travelArrival ? moment(this.formData.flight.travelArrival, 'YYYY-MM-DD').format('D-MMMM-YYYY') : '';
    },
    computedDepartureDate() {
      return this.formData.flight?.travelDeparture ? moment(this.formData.flight.travelDeparture, 'YYYY-MM-DD').format('D-MMMM-YYYY') : '';
    },
    computedIntoQuarantineDate() {
      return this.formData.intoQuarantineDate ? moment(this.formData.intoQuarantineDate, 'YYYY-MM-DD').format('D-MMMM-YYYY') : '';
    },
    computedBirthDate() {
      return this.formData.dateOfBirth ? moment(this.formData.dateOfBirth, 'YYYY-MM-DD').format('D-MMMM-YYYY') : '';
    }
  },
  mounted() {},
  methods: {}
};
</script>