<template>
  <v-autocomplete
    :value="valueToDisplay"
    :class="required ? 'required' : ''"
    :items="countries"
    v-on:change="updateCountry($event)"
    :rules="validationRules"
    color="primary"
    clearable
    :label="label"
  ></v-autocomplete>
</template>

<script>
import { mapGetters } from 'vuex';
import listOfCountriesJson from '@/store/data/list-of-countries.json';

export default {
  props: ['required', 'label', 'keyName', 'value', 'extraItems', 'selectedItem'],
  data() {
    return {
      validationRules: [],
      countries: null,
      valueToDisplay: null
    };
  },
  watch: {
    value(n) {
      if (n) this.valueToDisplay = n;
    }
  },
  created() {
    this.createArrayOfListOfCountries(listOfCountriesJson);
    if (this.required) {
      this.validationRules = [(v) => v != null || 'This field is required'];
    }
    if (this.value) this.valueToDisplay = this.value;
    else if (this.selectedItem) this.valueToDisplay = this.selectedItem;
  },
  methods: {
    createArrayOfListOfCountries(list) {
      const newList = list.map((countryObj) => {
        const newList = {};
        newList['value'] = countryObj.name;
        newList['text'] = countryObj.name;
        return newList;
      });

      this.countries = newList;
      if (this.extraItems) this.countries.unshift(this.extraItems.name);
    },

    updateCountry(value) {
      if (this.keyName) this.$parent.$parent.$parent.updateValue(value, this.keyName);
    }
  },
  computed: {
    ...mapGetters(['applicantInformation'])
  }
};
</script>