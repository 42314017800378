<template>
  <div :id="id" class="hide-print">
    <div class="text mono-font">
      <img class="logo" :src="require('@/assets/images/generic/logo-dvg.svg')" style="max-width: 300px" />
      <table class="table table-sm" v-if="linkedUser">
        <tbody>
          <tr v-if="linkedUser.edCardId">
            <td class="col1">Type:</td>
            <td>{{ linkedUser.edCardId }}</td>
          </tr>
          <tr>
            <td class="col1">First Name:</td>
            <td>{{ linkedUser.firstname }}</td>
          </tr>
          <tr>
            <td class="col1">Last Name:</td>
            <td>{{ linkedUser.lastname }}</td>
          </tr>
          <tr v-if="linkedUser.birthDay">
            <td class="col1">Birth Date:</td>
            <td>{{ computedBirthDate }}</td>
          </tr>
          <tr>
            <td class="col1">E-Mail:</td>
            <td>{{ linkedUser.email }}</td>
          </tr>
          <tr v-if="linkedUser.gender">
            <td class="col1">Gender:</td>
            <td>{{ linkedUser.gender }}</td>
          </tr>
          <tr>
            <td class="col1">Address</td>
            <td>{{ linkedUser.accommodationAddress }}</td>
          </tr>
          <tr v-if="linkedUser.phonenumber">
            <td class="col1">Phone:</td>
            <td>{{ linkedUser.phonenumber }}</td>
          </tr>
          <tr v-if="linkedUser.azvNumber">
            <td class="col1">AZV Number:</td>
            <td>{{ linkedUser.azvNumber }}</td>
          </tr>
          <tr v-if="linkedUser.doctorEntry && linkedUser.doctorEntry.name">
            <td class="col1">Doctor Name:</td>
            <td>{{ linkedUser.doctorEntry.name }}</td>
          </tr>
          <tr v-if="computedPaymentType">
            <td class="col1">Payment Method:</td>
            <td>{{ computedPaymentType }}</td>
          </tr>
          <tr>
            <td class="col1">Date & time :</td>
            <td>{{ currentDateTime }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <VueBarcode :value="lastScannedBarcode" format="CODE128" fontSize="40" width="2" lass="px-8 barcode"> Could not generate barcode </VueBarcode>
  </div>
</template>

<script>
import VueBarcode from 'vue-barcode';
import moment from 'moment';

export default {
  props: ['linkedUser', 'lastScannedBarcode', 'id'],
  components: {
    VueBarcode
  },

  computed: {
    computedArrivalDate() {
      return this.linkedUser.travelArrival ? moment(this.linkedUser.travelArrival, 'YYYY-MM-DD').format('D-MMMM-YYYY') : '';
    },
    computedDepartureDate() {
      return this.linkedUser.travelDeparture ? moment(this.linkedUser.travelDeparture, 'YYYY-MM-DD').format('D-MMMM-YYYY') : '';
    },
    computedBirthDate() {
      return this.linkedUser.birthDay ? moment(this.linkedUser.birthDay, 'YYYY-MM-DD').format('D-MMMM-YYYY') : '';
    },
    currentDateTime() {
      return moment().format('D-MMMM-YYYY / hh:mm A');
    },
    computedPaymentType() {
      const testKitArray = this.linkedUser.patientTestKit;
      if (testKitArray && testKitArray.length > 0) {
        const lastKitTags = JSON.parse(testKitArray[testKitArray.length - 1].tags);
        if (lastKitTags) return lastKitTags.paymentMethod;
        else return null;
      } else return null;
    }
  }
};
</script>

<style lang="scss">
.printSubject {
  .logo {
    width: 300px;
  }
}
</style>